<template>
    <div class="frutas-diferentes">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Percepción"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1617836231/juegos-cramer/tutoriales/nivel1/percepcion/frutas_diferentes_b9qcm4.mp4" 
        text="¿Cuál es el saco de fruta diferente?"
        ></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning" >
            <clock-down minuto="1" segundo="0" @setgameruning="setgameruning()"></clock-down>
            <div class="g-container">
                <div class="game-container">
                    <div  
                        v-for="(item,index) in bolsas" 
                        :key="item.id" 
                        class="fd__bag-box__container"
                        @click="getKeyRes(item.clave,index)"
                    >
                        <div class="fd__bag-box">
                            <div class="fd__fruits-box">
                                <div 
                                    v-for="e in item.posfruta" 
                                    :key="e.id"
                                    :class="['fruit', 'fd__pos'+e.pos,'fruta'+e.fruta]"
                                    ></div>
                                
                            </div>
                            <div v-if="visibleAnswer&&(nro_ans_result==index)" :class="['box-status',statusAnswer]">

                            </div>
                        </div>
                        
                    </div>
                    <div v-if="visibleAnswer" class="capa-protectora"></div>
                </div>
            </div>
            <div style="color:white;">{{bolsas.posfruta}}</div>
        </div>
        <div v-if="!gameRuning">
            <results-game nombrejuego="frutas diferente" nivel="1" inteligencia="percepcion"></results-game>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame.vue'
import TutorialGame from '@/components/TutorialGame.vue'
import ClockDown from '@/components/ClockDown.vue'
import ResultsGame from '@/components/ResultsGame.vue'
import {mapMutations,mapState} from 'vuex'
export default {
    components:{
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame
    },
    data:()=>({
        puntos:0,
        frutasSected:[],
        posfrutas:[],
        auxp:0,
        bolsas:[],
        statusAnswer:'',
        visibleAnswer:true,
        nro_ans_result:0,
        gameRuning:true
    }),
    computed:{
        ...mapState(['tutorialStatus'])
    },
    methods:{
        ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
        setgameruning(){
            this.gameRuning = false
            if(this.puntos<0)
            {
                this.changeScoreGame(0)
                this.changeScoreDescription('insuficiente')
            }
            else{
                this.changeScoreGame(this.puntos)
                if(this.puntos<=200){
                this.changeScoreDescription('insuficiente')
                }
                else if(this.puntos<=400){
                    this.changeScoreDescription('escasa')
                }
                else if(this.puntos<=600){
                    this.changeScoreDescription('suficiente')
                }
                else if(this.puntos<=800){
                    this.changeScoreDescription('buena')
                }
                else{
                    this.changeScoreDescription('optima')
                }
            }
            
        },
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min+1) ) + min;
        },
        getKeyRes(key,index){
            this.nro_ans_result = index
            this.visibleAnswer = true;
            if(key)
            {
                let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                a.play()
                this.puntos = this.puntos + 60;
                this.statusAnswer = 'comprobado'
            }
            else{
                let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                a.play()
                this.puntos = this.puntos - 10;
                this.statusAnswer = 'cancelar';
            }

            

            window.setTimeout(()=>{
                this.gameGenerator()
            },1000)
            
            
        },
        getArrayRandom(min,max){
            //let total = max-min;
            let res = [];
            while(!(res.length==4))
            {
                let aux = this.getRandom(min,max);
                let a = res.find((e)=>e==aux)
                if(!a){
                    res.push(aux)
                }
            }
            //console.log(res)
            return res;
        },
        reordenarArray(arr){
            let a = arr.sort(function() { return Math.random() - 0.5 })
           // console.log(a)
           return a;
        },
        cambiarPosiciones(arr){
            let a = [] 
            a=[...arr]//[...this.posfrutas];
            let arand = this.getArrayRandom(1,6)
            //let a = this.posfrutas.map((e)=>{
            //    e.pos = this.auxp++;
            //    return e;
            //})
            a.forEach((e,i) => {
                e.pos = arand[i];
            });
            //a[0].pos = this.auxp++;
            //console.log(a)
            return a;
        },
        gameGenerator(){

            this.bolsas = []
            this.visibleAnswer = false
            this.statusAnswer = ''
            /*posiciones de frutas 6 */
            /*cantidad de frutas 9 */
            let frutas_selecionadas = this.getArrayRandom(1,9)
            let posiciones = this.getArrayRandom(1,6)
            this.bolsas.push(
                {
                    pos:this.getRandom(1,30),
                    clave:false,
                    posfruta:[
                        {
                            pos:posiciones[0],
                            fruta:frutas_selecionadas[0]
                        },
                        {
                            pos:posiciones[1],
                            fruta:frutas_selecionadas[1]
                        },
                        {
                            pos:posiciones[2],
                            fruta:frutas_selecionadas[2]
                        },
                        {
                            pos:posiciones[3],
                            fruta:frutas_selecionadas[3]
                        }
                    ]
                }
            );
            let posiciones2 = this.reordenarArray(posiciones);
            this.bolsas.push(
                {
                    pos:this.getRandom(1,30),
                    clave:false,
                    posfruta:[
                        {
                            pos:posiciones2[0],
                            fruta:frutas_selecionadas[0]
                        },
                        {
                            pos:posiciones2[1],
                            fruta:frutas_selecionadas[1]
                        },
                        {
                            pos:posiciones2[2],
                            fruta:frutas_selecionadas[2]
                        },
                        {
                            pos:posiciones2[3],
                            fruta:frutas_selecionadas[3]
                        }
                    ]
                }
            );
            let posiciones3 = this.reordenarArray(posiciones2);
            this.bolsas.push(
                {
                    pos:this.getRandom(1,30),
                    clave:false,
                    posfruta:[
                        {
                            pos:posiciones3[0],
                            fruta:frutas_selecionadas[0]
                        },
                        {
                            pos:posiciones3[1],
                            fruta:frutas_selecionadas[1]
                        },
                        {
                            pos:posiciones3[2],
                            fruta:frutas_selecionadas[2]
                        },
                        {
                            pos:posiciones3[3],
                            fruta:frutas_selecionadas[3]
                        }
                    ]
                }
            );
            let posiciones4 = this.reordenarArray(posiciones3);
                let r=0;
                while(frutas_selecionadas.length!=3)
                {
                    r = this.getRandom(1,9)
                    let busqueda_duplicado = frutas_selecionadas.find(e=>e==r)
                    if(!busqueda_duplicado){
                        frutas_selecionadas.pop()
                        
                    }
                }
                frutas_selecionadas.push(r)
            this.bolsas.push(
                {
                    pos:this.getRandom(1,30),
                    clave:true,
                    posfruta:[
                        {
                            pos:posiciones4[0],
                            fruta:frutas_selecionadas[0]
                        },
                        {
                            pos:posiciones4[1],
                            fruta:frutas_selecionadas[1]
                        },
                        {
                            pos:posiciones4[2],
                            fruta:frutas_selecionadas[2]
                        },
                        {
                            pos:posiciones4[3],
                            fruta:frutas_selecionadas[3]
                        }
                    ]
                }
            );

            this.bolsas.sort((a,b)=>a.pos-b.pos)
            
        }

    },
    mounted(){
        this.changeMenuBarBottom(false)
        this.gameGenerator()
        this.changeTutorialStatus(true)
        //console.log(this.bolsas)
    }
}
</script>
<style>
    .g-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .frutas-diferentes{
        background-color:#1F0275;
        height: 100vh;
    }
    .game-container{
        width: 95%;
        margin-top:2em;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 25vh 25vh;
        grid-gap:1.5em;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color:#EEEFE4;
        border-radius: 10px;
    }
    .fd__bag-box__container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fd__bag-box{
        width: 8em;
        height: 8em;
        position: relative;
        display: flex;
        justify-content: center;
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/saco.svg');
        background-size: 100% 100%;
        cursor: pointer;
    }
    .box-status{
        position: absolute;
        width: 3em;
        height: 3em;
        right: 0px;
        background-size: 100% 100%;
    }
    .capa-protectora{
        position: absolute;
        width: 95%;
        height: 55vh;
        background-color: red;
        opacity: 0;
    }
    .comprobado{
        background-image: url('../../../assets/juegos/comunes/comprobado.svg');
    }
    .cancelar{
        background-image: url('../../../assets/juegos/comunes/cancelar.svg');
    }
    .fd__fruits-box{
        width: 7em;
        height: 5em;
        /*background-color: black;
        opacity: .4;*/
        position: absolute;
        display: grid;
        grid-template-rows: 2.5em 2.5em;
        grid-template-columns: repeat(3,30%);
    }
    .fruit{
        width: 3em;
        height: 3em;
        /*border:solid black 3px;*/
       /* background-color: red;*/
    }
    .fd__pos1{
        grid-column: 1 / 1;
        grid-row: 1 / 1;
    }
    .fd__pos2{
        grid-column: 2 / 2;
        grid-row: 1 / 1;
    }
    .fd__pos3{
        grid-column: 3 / 3;
        grid-row: 1 / 1;
    }
    .fd__pos4{
         grid-column: 1 / 1;
        grid-row: 2 / 2;
    }
    .fd__pos5{
        grid-column: 2 / 2;
        grid-row: 2 / 2;
    }
    .fd__pos6{
       grid-column: 3 / 3;
        grid-row: 2 / 2;
    }
    
    .fruta1{
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/pera.svg');
        background-size: 100% 100%;
    }
    .fruta2{
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/fresa.svg');
        background-size: 100% 100%;
    }
    .fruta3{
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/naranja.svg');
        background-size: 100% 100%;
    }
    .fruta4{
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/platano.svg');
        background-size: 100% 100%;
    }
    .fruta5{
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/plum.svg');
        background-size: 100% 100%;
    }
    .fruta6{
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/sandia.svg');
        background-size: 100% 100%;
    }
    .fruta7{
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/tomate.svg');
        background-size: 100% 100%;
    }
    .fruta8{
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/lechuga.svg');
        background-size: 100% 100%;
    }
    .fruta9{
        background-image: url('../../../assets/juegos/nivel1/percepcion/j1/uvas.svg');
        background-size: 100% 100%;
    }
    @media (min-width: 900px) and (orientation: landscape) {
    .game-container{
        width: 60%;
        }   
    }
</style>